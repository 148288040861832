import {
	View
} from '../view';

/**
 * Gets loaded in the <head>.
 * This script will not reload on page change,
 * due to turbolinks.
 */
class Menu extends View {
	constructor(el, settings = {}) {

		settings.displayName = 'Menu';
		super(el, settings);

		this.menu = el;
		this.scrollElement = document.querySelector('.sh-main-menu__inner');

		window.menuIsClosed = window.innerWidth > 1024 ? false : true;

		this.openDropDownClass = 'sh-submenu-toggled';
		this.activeClass = 'is-current';
		this.activeElement = null;

		this.currentScroll = 0;

		this.initExpandMenu();
	}
	
	delegateEvents() {
		this.on('click', '.sh-menu-toggle', this.onMenuClick);
		document.querySelector('.sh-main-menu__inner').addEventListener('scroll', this.setScrollRef.bind(this));
		document.addEventListener("turbolinks:render", () => {
			this.scrollElement.scrollTo(0, this.currentScroll);
		})
	}

	/**
	 * If page load is for a sub page, expand the menu accordingly
	 */
	initExpandMenu() {
		const path = window.location.pathname;
		const links = [...this.menu.querySelectorAll('a')];
		const activeLink = links.find(link => link.getAttribute('href') === path);
		const activeLi = activeLink && activeLink.parentElement;

		// page load != subpage
		if (!activeLi) {
			return;
		}

		const openDropDowns = el => {
			if (el === this.menu) {
				return;
			}

			const parent = el.parentElement;

			parent.matches('li') && parent.classList.add(this.openDropDownClass);
			openDropDowns(parent);
		}

		activeLi.classList.add(this.activeClass);
		this.activeElement = activeLi;
		openDropDowns(activeLi.parentElement);
	}

	/**
	 * eventhandler for menu item clicks
	 */
	onMenuClick(e) {
		const link = e.currentTarget;
		const linkUrl = link.getAttribute('href');
		const parent = link.parentElement;

		// handle new-tabs-links as default
		if (link.getAttribute('target') === '_blank') {
			return;
		}

		e.preventDefault();

		if (this.isDropDownToggle(link)) {
			parent.classList.toggle(this.openDropDownClass);
		}

		if (linkUrl != '#' && this.notCurrentPath(linkUrl)) {
			Turbolinks.visit(link.href);
			this.toggleActiveClass(this.activeElement, parent);
			this.activeElement = parent;
		}
	}


	/**
	 * Toggles active class on all passed els
	 */
	toggleActiveClass(...els) {
		els.forEach(el => el && el.classList.toggle(this.activeClass));
	}

	/**
	 * check if current url != passed url
	 */
	notCurrentPath(href) {
		return window.location.pathname != href;
	}

	/**
	 * check if el is dropdown toggler
	 */
	isDropDownToggle(el) {
		return el.parentElement.querySelector('ul');
	}

	/**
	 * save reference of how much the menu is scrolled
	 */
	setScrollRef(event) {
		this.currentScroll = event.target.scrollTop
	}
}
export default Menu;
