import { View } from '../view';

/**
 * Gets loaded in the <head>.
 * This script will not reload on page change,
 * due to turbolinks.
 */
class ToggleMenu extends View {
	constructor(el, settings = {}) {
		settings.displayName = 'ToggleMenu';
		super(el, settings);

		this.mainContainer = this.find('.sh-main-container', document)[0];
		this.menuContainer = this.find('.sh-main-menu', this.mainContainer)[0];

		this.handleResize();
		if (window.matchMedia("(max-width: 1024px)").matches && window.menuIsClosed || window.menuIsClosed) {
			this.addClosedClass(true)
		}
	}

	delegateEvents() {
		this.on('click', this.el, this.toggleMenu);
	}

	toggleMenu() {
		this.addClosedClass(window.menuIsClosed ? false : true)
	}

	handleResize() {
		this.lastWindowWidth = window.innerWidth;
		this.newWindowWidth = 0;
		let throttled = false;
		window.onresize = () => {
			if (!throttled) {
				this.doResize();
				throttled = true;
				setTimeout(function () {
					throttled = false;
				}, 100);
			}
		};
	}

	doResize() {
		this.newWindowWidth = window.innerWidth;

		if (window.matchMedia("(max-width: 1024px)").matches) {
			if (this.lastWindowWidth > this.newWindowWidth) {
				this.addClosedClass(true)
			}
		} else {
			if (this.lastWindowWidth < this.newWindowWidth) {
				this.addClosedClass(false)
			}
		}

		this.lastWindowWidth = window.innerWidth;
	}

	addClosedClass(bool) {
		if (bool) {
			this.menuContainer.classList.add('mobile-menu-closed');
			window.menuIsClosed = true
		} else {
			this.menuContainer.classList.remove('mobile-menu-closed');
			window.menuIsClosed = false
		}
	}
}

export default ToggleMenu;
