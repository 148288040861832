import Turbolinks from 'turbolinks';
import Menu from './components/menu-turbolinked';
import ToggleMenu from './components/toggle-menu-turbolinked';

import { View } from './view';

console.log('HEAD SCRIPT LOADED')

Turbolinks.start();

// We need to wait for everything to load before we can use DOM and DATA
window.addEventListener('load', () => {
    new Menu(document.querySelectorAll('[data-sh-menu]')[0]);
    View.viewFactory('.js--toggle-menu', ToggleMenu);
});